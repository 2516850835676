.visaApplied-card {
    display: flex;
    background-color: white;
    border-radius: 16px;
    padding: 24px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    // width: 650px;
    width: 100%;
    margin: 0 auto;
    gap: 24px;
    border: 1px solid #e0e0e0;
    margin-top: 15px;
}

.visaApplied-card-image {
    width: 150px;
    height: 150px;
    background-color: #e0e0e0;
    border-radius: 8px;
    flex-shrink: 0;
}

.visaApplied-card-content {
    flex: 1;
}

.visaApplied-card-title {
    font-size: 24px;
    font-weight: 700;
    margin: 0 0 24px 0;
    color: #000;
}

.visaApplied-card-dates {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    width: 80%;
    // border: 1px solid red;
}

.visaApplied-date-section {
    // flex: 1;
    // text-align: center;

}

.visaApplied-date-label {
    font-size: 18px;
    color: #555;
    margin: 0;
}

.visaApplied-date-value {
    font-size: 16px;
    font-weight: 600;
    margin: 4px 0 0 0;
}

.visaApplied-airplane-icon-container {
    // border: 1px solid red;
}

.visaApplied-airplane-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    // border: 1px solid red;
}

.visaApplied-travelers-info,
.visaApplied-amount-info,
.visaApplied-status-info {
    font-size: 16px;
    margin: 12px 0;
    color: #333;
}

.visaApplied-highlight {
    font-weight: 600;
    color: #000;
}

.visaApplied-amount {
    font-weight: 600;
}

.visaApplied-status {
    font-weight: 600;
}

@media (max-width: 768px) {
    .visaApplied-card {
        flex-direction: column;
        padding: 16px;
    }

    .visaApplied-card-image {
        width: 100px;
        height: 100px;
        margin-bottom: 16px;
    }

    .visaApplied-card-title {
        font-size: 24px;
        margin-bottom: 16px;
    }

    .visaApplied-date-label {
        font-size: 16px;
    }

    .visaApplied-date-value {
        font-size: 18px;
    }

    .visaApplied-travelers-info,
    .visaApplied-amount-info,
    .visaApplied-status-info {
        font-size: 16px;
    }
}