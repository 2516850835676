.information {
    width: 25%;
    background-color: #F1F5F9;
    // padding-left: .5rem;
    display: flex;
    justify-content: center;
    // align-items: center;

    @media (max-width: 600px) {
        width: 100%;
    }

    &__box {
        width: 90%;
        background-color: $color-white;
        border-radius: 1rem;
        margin-top: 2rem;
        height: 27rem;
        padding: 1rem;

        @media (max-width: 600px) {
            height: 23rem;
            margin-bottom: 2rem;
        }
    }

    &__header {
        display: flex;
        border-bottom: 1px solid #E4E6EA;
        align-items: center;
        padding: 1rem 0rem;
    }

    &__icon {
        width: 2.3rem;
        height: 2.3rem;
        margin-right: 1rem;
    }

    &__title {
        font-family: $text-mulish;
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 2rem;
        letter-spacing: 0.02em;
        text-align: center;
    }

    &__text {
        font-family: $text-mulish;
        font-size: 1.3rem;
        font-weight: 400;
        line-height: 1.7rem;
        text-align: left;
        color: #48505C;
        margin-top: 1rem;

    }

    &__steps {}

    &__step {
        margin: 0.5rem 0rem;
        display: flex;
        align-items: center;

        &-number {
            background-color: $color-primary;
            color: $color-white;
            font-size: 1rem;
            border-radius: 50%;
            font-family: $text-mulish;
            width: 1.6rem;
            height: 1.6rem;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: .5rem;
        }

        &-description {
            font-family: $text-mulish;
            font-size: 1.4rem;
            font-weight: 400;
            line-height: 2rem;
            color: #48505C;

        }
    }
}