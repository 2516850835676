.dashboard {

    display: flex;

    @media screen and (max-width: 600px) {
        display: flex;
        flex-direction: column;
    }

    &__header {
        width: 40%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: static;
        //    background-color: rgba($color-grey,0.1);
        z-index: 1;

        @media screen and (max-width: 600px) {
            width: 100%;
        }
    }

    &__content {
        width: 60%;
        display: flex;
        flex-direction: column;
        max-height: 90vh;
        padding: 2rem;

        @media screen and (max-width: 600px) {
            width: 100%;
            // border: 1px solid red;
        }
    }

    &__user-info {
        height: 85vh;
        width: 28rem;
        border-radius: 2rem;
        margin: 2rem 0rem;
        box-shadow: 0px 0px 4.6px 0px #00000040;
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        @media screen and (max-width: 600px) {
            width: 90%;
            height: 35vh;
        }
    }

    &__user-icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-bottom: 1px solid grey;
        margin: 2rem 0rem;
    }

    &__icon {
        fill: grey;
        margin-bottom: 2rem;
        width: 7rem;
        height: 7rem;
        border: 1px solid grey;
        border-radius: 50%;
        padding: 0.2rem;
    }

    &__user-mobile {
        font-size: 1.6rem;
        text-align: center;
        font-family: $text-mulish;
        margin-bottom: 1rem;

    }

    &__logout-button {
        width: 80%;
        height: 4rem;
        margin-bottom: 2rem;
        border: none;
        font-size: 1.6rem;
        background-color: $color-primary;
        border-radius: 2rem;
        color: $color-white;
        cursor: pointer;
        outline: none;

        &:hover {
            background-color: rgba($color-primary, 0.9);
        }

        &:active {
            transform: translateY(1px)
        }
    }

    &__content-heading {
        font-family: $text-mulish;
        font-size: 2rem;
        font-weight: 700;
        line-height: 1.8rem;
        margin-bottom: 0.7rem;

    }

    &__visa-list {
        width: 100%;
        max-height: 100vh;
        overflow-y: scroll;
        // border: 1px solid red;
    }

}