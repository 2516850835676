.travellerNavigation {
    width: 100%;
    display: flex;
    flex-direction: row;

    &__container {
        display: flex;

        @media (max-width: 600px) {
            flex-direction: column;
        }
    }
}