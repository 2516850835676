.visaDetail {
    display: flex;
    justify-content: center;
    padding: 3rem 0rem;
    flex-direction: column;
    align-items: center;

    &__container {
        width: 90%;
        display: flex;
        justify-content: center;
        flex-direction: column;

        @media (max-width: 600px) {
            flex-direction: column;
            width: 100%;
        }
    }

    &__box-container {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    &__box--left {
        width: 100%;
        display: flex;
        gap: 3rem;
        flex-direction: row;
        padding: 0rem 1rem;
        justify-content: space-between;
        // border: 1px solid red;

        @media (max-width: 600px) {
            width: 95%;
            flex-direction: column;
            margin-left: auto;
            margin-right: auto;
        }
    }

    &__box--left--container-top {
        width: 70%;

        // border: 1px solid red;
        @media (max-width: 600px) {
            width: 95%;
            margin-left: auto;
            margin-right: auto;
        }
    }

    &__box--right {
        width: 30%;

        // border: 1px solid red;
        @media (max-width: 600px) {
            width: 90%;
            margin-left: auto;
            margin-right: auto;
        }
    }

    &__heading {
        font-size: 3rem;
        font-weight: 700;
        line-height: 3.6rem;
        color: $color-black;
        margin-bottom: 2rem;
        font-family: "Volkhov", serif;
        margin-left: .5rem;
        // border: 1px solid red;
        // width: 100%;
    }

    &__image-box {
        width: 100%;
        height: 40rem;
        margin-bottom: 2rem;
    }

    &__img {
        width: 100%;
        height: 100%;
        border-radius: 1rem;
    }

    &__apply-text {
        font-family: "Mulish", sans-serif;
        font-size: 1.8rem;
        font-weight: 400;
        // line-height: 3.2rem;
        color: $color-black;
        margin-bottom: 0.5rem;
        margin-left: .5rem;
    }

    &__get-date {
        display: flex;
        align-items: center;
        font-family: "Mulish", sans-serif;
        font-size: 1.4rem;
        font-weight: 600;
        // line-height: 3.2rem;
        color: $color-black;
        margin-left: .5rem;
        margin-bottom: 2rem;

        &>span {
            color: $color-primary;
        }
    }

    &__icon {
        width: 1.6rem;
        height: 1.6rem;
        margin-right: 1rem;
    }

    &__subHeading {
        font-family: "Volkhov", serif;
        font-size: 2.3rem;
        font-weight: 700;
        line-height: 4rem;
        $color: $color-black;
        margin-bottom: 1.5rem;
        text-align: center;

    }

    &__freqAsked {
        width: 100%;

        @media (max-width: 600px) {
            width: 90%;
            margin-left: auto;
            margin-top: 2rem;
            margin-right: auto;

        }
    }

    &__review {
        width: 90%;
        display: flex;
        justify-content: space-between;
    }

    &__document {
        border: 1px solid #E5E7EB;
        box-shadow: 0px 4px 6px -4px #0000001A;
        box-shadow: 0px 10px 15px -3px #0000001A;
        margin-bottom: 5rem;

        @media (max-width: 600px) {
            margin-bottom: 0rem;
        }

        &--box1 {
            border-bottom: 1px solid #E5E7EB;
            font-family: "Volkhov", serif;
            font-size: 1.6rem;
            font-weight: 600;
            line-height: 2.4rem;
            background: #F4F4F4;
            color: $color-black;
            padding: 1rem;
            display: flex;
            align-items: center;
        }

        &--box2 {
            font-family: "Mulish", sans-serif;
            font-size: 1.4rem;
            font-weight: 400;
            line-height: 2.4rem;
            color: $color-black;
            padding: 1rem;
            display: flex;
            align-items: center;
        }

        &-icon {
            width: 1.6rem;
            height: 1.6rem;
            margin-right: 1rem;
        }
    }

    &__box {
        margin-right: 1.5rem;
        display: flex;
        align-items: center;
    }



}