.statisticSection {

    display: flex;
    justify-content: center;
    padding: 3rem 0rem;
    width: 80%;
    margin-bottom: 2rem;

    &__container {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

        @media (max-width: 50em) {
            width: 100%;
        }

    }

    &__box {
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (max-width: 56.25em) {
            width: 35%;
            margin: 2rem
        }
    }

    &__icon-box {
        box-shadow: 0px 0px 4.6px 0px #00000040;
        width: 14.3rem;
        height: 12.5rem;
        border-radius: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;

        @media (max-width: 600px) {
            width: 14.3rem;
            height: 12.5rem;
        }

        @media (max-width: 400px) {
            width: 12.3rem;
            height: 10.5rem;
        }
    }

    &__icon {
        width: 6.3rem;
        height: 5.5rem;
        fill: $color-primary;

        @media (max-width: 400px) {
            width: 5.3rem;
            height: 4.5rem;
        }
    }

    &__text {
        font-size: 1.6rem;
        font-weight: 600;
        color: $color-black;
        margin-bottom: .8rem;

        @media (max-width: 400px) {
            font-size: 1.3rem;
        }
    }

    &__num {
        font-size: 2.3rem;
        font-weight: 600;
        color: $color-black;

        @media (max-width: 400px) {
            font-size: 1.6rem;
        }
    }
}

.statistic-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 1rem;
    margin-right: 1rem;

    &__heading {
        font-family: "Volkhov", serif;
        font-size: 3rem;
        font-weight: 600;
        line-height: 4rem;
        margin-bottom: 1rem;
    }

    &__subheading {
        font-family: "Mulish", sans-serif;
        font-size: 1.2rem;
        font-weight: 500;
        line-height: 1.7rem;
        margin-bottom: 2rem;
        color: $color-grey;
        text-align: center;
        ;
    }
}