// color variables

$color-primary: #5046E5;
$color-secondary: #D1A64A;
$color-green: #037d50;
$color-green-light:#7BBCB0;
$color-white: #FFFFFF;
$color-black: #000000;
$color-grey: #778088;
$color-grey-light: #E0E0E0;
$color-grey-2:  #1C2B38;
$color-grey-light-2: #BFBFBF;
$color-blue: #13253F;

//Typography

$text-mulish: "Mulish", sans-serif;