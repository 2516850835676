.slider-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: transparent;
}

.slider-wrapper {
  overflow: hidden;
  width: 83%;
  // width: 100%;
  margin-bottom: 2rem;
  background-color: transparent;
  /* border: 1px solid red; */
}

.slider-cards {
  display: flex;
  transition: transform 0.5s ease;
  width: max-content;
  flex-wrap: nowrap;
}

.slider__buttons {
  display: flex;
  justify-content: center;
}

.prev-button,
.next-button {
  cursor: pointer;
  border: none;
  background-color: transparent;
  margin: 1rem;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}


.prev-icon {
  width: 4.15rem;
  height: 4.15rem;
}

.next-icon {
  width: 4.15rem;
  height: 4.15rem;
}

.prev-button:disabled,
.next-button:disabled {
  cursor: not-allowed;
}