.otp {
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;

    &__wrapper {
        border: 1px solid #E5E7EB;
        border-radius: 1.6rem;
        padding: 2rem 3rem;
        position: relative;
    }

    &__header {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0rem 6rem 2rem 0rem;
    }

    &__logo {
        width: 4rem;
        height: 4rem;
        margin-right: 1.5rem;
    }

    &__icon {
        width: 100%;
        height: 100%;

    }

    &__title {}

    &__main-title {
        font-family: $text-mulish;
        font-size: 1.6rem;
        font-weight: 100;
        // line-height: 2.8rem;
        color: $color-primary;
    }

    &__sub-title {
        font-family: $text-mulish;
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 2rem;
        color: $color-primary;
    }

    &__form {}

    &__label {
        font-family: $text-mulish;
        font-size: 1.4rem;
        font-weight: 600;
        // line-height: 2.4rem;
        margin-bottom: 2rem;
    }

    &__input {
        margin-top: .5rem;
        width: 100%;
        height: 4rem;
        font-size: 1.4rem;
        border-radius: .5rem;
        outline: none;
        border: 1px solid black;
        margin-bottom: 1rem;
        padding: 1rem;
    }


    &__error {
        font-size: 1.4rem;
        color: red;
        margin-bottom: 1rem;
        margin-left: .5rem;
        margin-top: -.5rem;
    }

    &__buttons {
        display: flex;
        align-items: center;
        margin-bottom: 1.5rem;
    }

    &__resend-button {
        width: 40%;
        border: none;
        outline: none;
        background-color: $color-primary;
        color: $color-white;
        border-radius: .6rem;
        font-size: 1.4rem;
        padding: 1rem;
        margin-right: 1rem;
        cursor: pointer;

        &:active {
            transform: translateY(1px);
        }

        &:hover {
            background-color: rgba($color-primary, 0.9);
        }
    }

    &__continue-button {
        width: 60%;
        border: none;
        outline: none;
        background-color: $color-primary;
        color: $color-white;
        border-radius: .6rem;
        font-size: 1.4rem;
        padding: 1rem;
        cursor: pointer;

        &:active {
            transform: translateY(1px);
        }

        &:hover {
            background-color: rgba($color-primary, 0.9);
        }
    }

    &__back-button {
        // width: 10%;
        border: none;
        outline: none;
        background-color: $color-primary;
        color: $color-white;
        border-radius: 50%;
        font-size: 1.4rem;
        padding: .6rem;
        margin-bottom: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 10px;
        height: 3.3rem;
        width: 3.3rem;
        cursor: pointer;

        &:active {
            transform: translateY(1px);
        }

        &:hover {
            background-color: rgba($color-primary, 0.9);
        }

    }

}