.faq {
  box-shadow: 0px 0px 4.6px 0px #00000040;
  border-radius: 1.6rem;
  padding: 1rem;

  &__item {
    border-bottom: 1px solid #ddd;
    padding: 0.5rem 0;
    cursor: pointer;

    &:last-child {
      border-bottom: none;
    }
  }

  &__question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 500;
    color: $color-black;
    margin: 1rem;

    &:hover {
      color: $color-primary;
    }
  }

  &__icon {
    width: 2rem;
    height: 2rem;
    transition: transform 0.3s ease;

    &--open {
      transform: rotate(180deg); // Rotate when open
    }
  }

  &__answer {
    padding-top: 0.5rem;
    font-size: 1.4rem;
    color: $color-black;
    font-weight: 300;
    animation: fadeIn 0.3s ease-in-out;
    margin: 1.5rem 1rem;

    @keyframes fadeIn {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }
  }
}