// Colors
$primary-color: #6a0dad; // Purple
$secondary-color: #ffcc00; // Yellow
$text-color: #333;
$background-color: #e6e6fa;
$white: #ffffff;
$gray: #666;

// Global Styles
.about-container {
  font-family: "Arial", sans-serif;
  background-color: $background-color;
  color: $text-color;
  padding: 4rem 6rem;
  text-align: center;

  @media (max-width: 768px) {
    padding: 3rem;
  }
}

// Hero Section
.about-hero {
  padding: 5rem 0;
  
  .about-title {
    font-size: 3.5rem;
    font-weight: bold;
  }

  .about-subtitle {
    font-size: 1.8rem;
    color: $gray;
  }
}

// Sections (Mission, Story)
.about-mission, .about-story {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 4rem 4rem;
  text-align: left;

  .about-text {
    width: 40%;

    h2 {
      font-size: 2.5rem;
      margin-bottom: 1rem;
      color: darken($text-color, 20%);
    }

    p {
      font-size: 1.6rem;
      color: lighten($text-color, 10%);
      line-height: 1.6;
    }
  }

  .about-image {
    width: 40%;

    img {
      width: 70%;
      border-radius: 1rem;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;

    .about-text, .about-image {
      width: 100%;
    }
  }
}

// Why Choose Us Section
.about-why {
  margin: 4rem 0;

  h2 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }

  .about-features {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;

    .feature-card {
      background: $white;
      padding: 2rem;
      border-radius: 1rem;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s ease;

      &:hover {
        transform: translateY(-5px);
      }

      h3 {
        font-size: 1.8rem;
        margin-bottom: 1rem;
      }

      p {
        font-size: 1.4rem;
        color: $gray;
      }
    }
  }

  @media (max-width: 768px) {
    .about-features {
      grid-template-columns: 1fr;
    }
  }
}

// Testimonials Section
.about-testimonials {
  margin: 5rem 0;

  h2 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }

  .testimonial {
    background: $white;
    padding: 2rem;
    margin: 1rem auto;
    width: 60%;
    border-radius: 1rem;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;

    &:hover {
      box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
    }

    p {
      font-size: 1.6rem;
      font-style: italic;
    }

    h4 {
      font-size: 1.4rem;
      margin-top: 1rem;
      color: darken($gray, 10%);
    }
  }

  @media (max-width: 768px) {
    .testimonial {
      width: 90%;
    }
  }
}

// CTA Section
.about-cta {
  margin: 5rem 0;
  padding: 3rem;
  background: $primary-color;
  color: $white;
  border-radius: 1rem;
  text-align: center;

  h2 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.6rem;
    margin-bottom: 2rem;
  }

  .cta-button {
    padding: 1.2rem 2rem;
    font-size: 1.6rem;
    background: $secondary-color;
    border: none;
    color: $text-color;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: background 0.3s ease;

    &:hover {
      background: darken($secondary-color, 10%);
    }
  }
}
