.review {
    padding: 1rem 0rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;

    &__heading {
        font-family: "Volkhov", serif;
        font-size: 3rem;
        font-weight: 600;
        line-height: 3.5rem;
        margin-bottom: 1rem;
        text-align: center;
    }

    &__subheading {
        font-family: "Mulish", sans-serif;
        font-size: 1.2rem;
        font-weight: 500;
        line-height: 2rem;
        margin-bottom: 4rem;
        color: $color-grey;
        text-align: center;
    }
}