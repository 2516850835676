.addOns {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70%;

    &__header {
        background-color: $color-primary;
        padding: 1rem 2rem;
        display: flex;
        align-items: center;
        margin-bottom: 5rem;
        border-radius: 3rem;
    }

    &__icon {
        width: 2rem;
        height: 2rem;
        margin: 1rem;

        &-eSim {
            width: 4.5rem;
            height: 5.2rem;
            margin: .5rem 1rem;
        }

        &--traveller {
            width: 2.5rem;
            height: 2.5rem;
            margin-right: 2rem;
        }

        &--arrow {
            width: 2.6rem;
            margin-left: 1rem;
        }
    }

    &__date {
        font-family: $text-mulish;
        font-size: 1.8rem;
        font-weight: 600;
        line-height: 2rem;
        text-align: center;
        color: $color-white;
    }

    &__body {
        box-shadow: 0px 25px 50px -12px #00000040;
        width: 40%;
        border-radius: 1.6rem;
    }

    &__item {
        &--eSim {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $color-primary;
            border-radius: 1rem;
            width: 102%;
            margin: -5px;
        }

        &-title {
            font-family: $text-mulish;
            font-size: 1.8rem;
            font-weight: 400;
            line-height: 2.4rem;
            color: $color-white;
        }

        &--quantity {
            display: flex;
            justify-content: space-between;
            margin: 3rem 2rem 2rem 2rem;
            border-bottom: 1px solid #B8B8B8BF;
            padding-bottom: 1rem;
        }


        &--price {
            display: flex;
            justify-content: space-between;
            margin: 0rem 2rem 2rem 2rem;
            padding-bottom: 1rem;
        }

        &--total {
            display: flex;
            justify-content: space-between;
            margin: 0rem 2rem 1rem 2rem;
            padding-bottom: 1rem;
        }
    }

    &__quantity {
        display: flex;

        &-control {

            width: 2.5rem;
            height: 2.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #E5E7EB;
            border-radius: 50%;
            font-size: 1.8rem;
            cursor: pointer;
        }

        &-value {
            font-family: "Mulish", sans-serif;
            font-size: 1.5rem;
            font-weight: 400;
            line-height: 2.4rem;
            text-align: center;
            margin: 0rem 1rem;
        }
    }

    &__esim {
        display: flex;
        align-items: center;

        &-title {
            font-family: $text-mulish;
            font-size: 1.6rem;
            font-weight: 600;
            line-height: 2.4rem;
        }
    }

    &__price {

        &-title {
            font-family: $text-mulish;
            font-size: 1.6rem;
            font-weight: 600;
            line-height: 2.4rem;

        }

        &-value {
            font-family: $text-mulish;
            font-size: 1.6rem;
            font-weight: 400;
            line-height: 2.4rem;
        }
    }

    &__total {
        &-title {
            font-family: $text-mulish;
            font-size: 1.6rem;
            font-weight: 600;
            line-height: 2.4rem;
        }

        &-value {
            font-family: $text-mulish;
            font-size: 1.6rem;
            font-weight: 400;
            line-height: 2.4rem;
        }
    }

    &__footer {
        background-color: $color-secondary;
        padding: .5rem 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 5rem;
        border-radius: 1.2rem;
        width: 60%;
        cursor: pointer;

        &:active {
            transform: translateY(1px);
        }

        &:hover {
            background-color: rgba($color-secondary, 0.9);
        }
    }

    &__checkout {
        font-family: $text-mulish;
        font-size: 1.8rem;
        font-weight: 400;
        line-height: 2.4rem;
        text-align: center;
        color: $color-white;
    }


}