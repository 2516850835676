.policy {
  font-family: Arial, sans-serif;
  color: #333;

  padding: 3rem 2rem;

  &__title {
    font-size: 3rem;
    margin-bottom: 2rem;
    color: $color-primary;
  }

  &__intro {
    font-size: 1.8rem;
    margin-bottom: 2rem;
    line-height: 1.6;
    color: #555;
  }

  &__section {
    margin-bottom: 3rem;
  }

  &__heading {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: $color-primary;
  }

  &__text {
    font-size: 1.5rem;
    line-height: 1.2;
    color: #555;
    margin-bottom: 1rem;
  }

  &__list {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 2rem;
  }

  &__list-item {
    font-size: 1.5rem;
    color: #555;
    margin-bottom: .5rem;
    position: relative;
    padding-left: 1.5rem;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      color: $color-primary;
    }
  }
}