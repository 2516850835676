.visa-card {
  // width: 27rem;
  margin-bottom: 1rem;
  border-radius: .5rem;
  box-shadow: 0rem .1rem 1rem rgba($color-grey, 0.6);
  // border: .5px solid $color-grey;
  cursor: pointer;
  transition: all .1s;

  &:hover {
    box-shadow: 0rem .2rem 2rem rgba($color-grey, 0.6);
  }

  &:active {
    transform: translateY(1px);
  }

  &__image-wrapper {
    height: 25rem;

  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    border-radius: .5rem .5rem 0rem 0rem;

  }

  &__details {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    margin-top: .2rem;
  }

  &__heading {
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 2.2rem;
    margin-right: .2rem;
    color: $color-grey-2;
    overflow: hidden;
  }

  &__price {
    font-family: "Volkhov", serif;
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 1.1rem;
    color: $color-black;
    padding-top: .3rem;
    text-align: right;

    &>span {
      font-family: "Mulish", sans-serif;
      font-size: 1rem;
      font-weight: 600;
      line-height: .4rem;
      color: $color-grey;
    }
  }


  &__delivery {
    font-family: "Mulish", sans-serif;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.75rem;
    padding: .5rem 1rem;
    padding-bottom: 1.5rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;

    &>span {
      font-family: "Mulish", sans-serif;
      font-size: 1.6rem;
      font-weight: 700;
      line-height: 1.75rem;
      color: $color-primary;
    }
  }

  &__icon {
    width: 1.6rem;
    height: 1.6rem;
    margin-right: .8rem;
  }
}