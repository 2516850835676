.visa-search {
    width: 35%;
    position: relative;

    &__input {
        width: 100%;
        border-radius: 1.4rem;
        border: 4px solid $color-primary;
        height: 5rem;
        padding-left: 3.4rem;
        margin-bottom: 2rem;
        outline: none;
        font-size: 1.8rem;

        &::placeholder {
            font-size: 1.6rem;
        }
    }

    &__container {
        display: flex;
    }

    &__icon {
        position: absolute;
        left: 1rem;
        margin-top: 1.4rem;
        width: 2.2rem;
        height: 2.2rem;
        color: $color-primary;
    }

    &__link {
        width: 100%;
        text-decoration: none;
        color: $color-black;
    }

    &__list {
        width: 100%;
        max-height: 25rem;
        overflow-y: scroll;
        z-index: 1;
        position: absolute;
        top: 6rem;
        background-color: $color-white;
        border-radius: 1rem;
        border: 1px solid rgba($color-grey, 0.6);
        display: flex;
        flex-direction: column;
        align-items: center;

        &-item {
            width: 100%;
            height: 6rem;
            padding: 1rem 1rem;
            display: flex;
            align-items: center;
            margin-bottom: .3rem;
            cursor: pointer;
        }

    }

    &__image {
        width: 4rem;
        height: 4rem;
        border-radius: .5rem;
        object-fit: cover;
        margin-right: 1rem;
    }

    &__country-name {
        font-size: 1.8rem;

        &:hover {
            color: $color-primary;
        }
    }

    &__no-results {
        width: 100%;
        font-size: 1.8rem;
        text-align: center;
    }


}

@media (max-width: 600px) {
    .visa-search {
        width: 90%;
        position: relative;
    }
}