.visaStats {

    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 1rem;
    background-color: $color-primary;
    width: 100%;
    padding: 1.3rem 1rem;
    margin-top: 5.5rem;
    // margin-left: 1rem;

    @media (max-width: 600px) {
        margin-top: 0rem;
    }

    &__box {
        display: flex;
        align-items: center;
        margin: .1rem;
    }

    &__icon {
        fill: $color-white;
        width: 1.8rem;
        height: 1.8rem;
        margin-right: 1rem;
    }

    &__text {
        color: $color-white;
        font-family: "Mulish", sans-serif;
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 2.4rem;
        text-align: left;
    }
}