.contact-page {
  font-family: Arial, sans-serif;
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4rem 6rem;
  background: linear-gradient(to right, #f5f5f5, #e6e6fa);

  @media (max-width: 768px) {
    flex-direction: column;
  }

  &__left {
    width: 45%;
    padding: 2rem;

    @media (max-width: 768px) {
      width: 100%;
      // padding: 2rem;
    }
  }

  &__title {
    font-size: 3rem;
    margin-bottom: 1.5rem;
    font-weight: bold;
  }

  &__info {
    font-size: 1.3rem;
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;

    svg {
      margin-right: 1rem;
      font-size: 2rem;
      transition: color 0.3s ease;
      cursor: pointer;

      &:hover {
        color: #6a0dad;
      }
    }
  }

  &__form {
    width: 45%;
    padding: 3rem;
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
    border-radius: 1rem;
    box-shadow: 0 0.8rem 1.5rem rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
      width: 100%;
      // padding: 2rem;
    }
  }

  &__form-group {
    margin-bottom: 2rem;
  }

  &__label {
    display: block;
    font-size: 1.2rem;
    margin-bottom: 0.8rem;
  }

  &__input,
  &__textarea {
    width: 100%;
    padding: 1.2rem;
    font-size: 1.6rem;
    border: 0.1rem solid #ccc;
    border-radius: 0.6rem;
    background: #e294ee; // Light violet background initially
    transition: border-color 0.3s ease, background-color 0.3s ease;
    resize: vertical;

    &:focus {
      border-color: #0000ff; // Blue border while typing
      background: #7c1fb2; // Light sky blue background while typing
      outline: none;
    }

    &:not(:placeholder-shown) {
      background: #add8e6; // Light blue after user has typed
    }

    &::placeholder {
      color: #ffffff; // Light purple placeholder (fixed)
      opacity: 1;
    }
  }

  &__form-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  &__button {
    display: block;
    width: 50%;
    padding: 1.3rem;
    font-size: 1.5rem;
    background-color: $color-primary;
    color: white;
    border: none;
    border-radius: 0.6rem;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;

    &:hover {
      background-color: #5b0dab;
      transform: translateY(-2px);
    }
  }
}