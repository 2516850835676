.travellerVerificationForm {
    box-shadow: 0px 1px .45rem 0px rgba($color-black, .25);
    border-radius: .5rem;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2.6rem 2.2rem;



    &__heading {
        font-family: $text-mulish;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.5rem;
        text-align: left;
        color: black;
        margin-bottom: 2rem;
    }

    &__form {
        display: flex;
        flex-wrap: wrap;
    }

    &__field {
        display: flex;
        flex-direction: column;
        width: 45%;

        margin-right: 2rem;
    }

    &__label {
        font-size: 1.8rem;
        margin: 1.5rem 1rem .8rem 0rem;
        font-weight: 500;
    }

    &__input {
        width: 100%;
        height: 4rem;
        border-radius: .5rem;
        outline: none;
        padding-left: 1rem;
        color: $color-grey-light-2;
        border: 1px solid $color-grey-light-2;
        font-size: 1.6rem;
    }

    &__submit {
        display: flex;
        justify-content: flex-end;
        height: 5rem;

        &-button {
            font-family: $text-mulish;
            font-size: 1.8rem;
            font-weight: 400;
            line-height: 2.4rem;
            text-align: center;
            width: 11.7rem;
            height: 100%;
            border-radius: 1.2rem;
            border: none;
            outline: none;
            background-color: $color-primary;
            color: $color-white;
            cursor: pointer;
            margin-top: 1rem;

            &:active {
                transform: translateY(1px);
            }
        }
    }
}