.visaCardSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3rem 2rem;
    padding-top: 6rem;
    margin-top: -9.5rem;

    &__heading {
        font-size: 3.2rem;
        font-weight: 700;
        line-height: 4.64rem;
        font-family: "Volkhov", serif;
    }

    &__subHeading {
        font-size: 1.2rem;
        font-weight: 500;
        line-height: 2.5rem;
        color: $color-grey;
        text-align: center;
        font-family: "Mulish", sans-serif;
    }
}

@media (max-width: 600px) {
    .visaCardSection {
        &__heading {
            font-size: 3.2rem;
            font-weight: 700;
            line-height: 4.64rem;
            text-align: center;
            font-family: "Volkhov", serif;
        }

        &__subHeading {
            font-size: 1.2rem;
            font-weight: 500;
            line-height: 2rem;
            color: $color-grey;
            text-align: center;
            font-family: "Mulish", sans-serif;
        }
    }
}