.payment {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // min-width: 70%;
    width: 100%;
    min-height: 80vh;

    &__header {
        background-color: $color-primary;
        padding: 1rem 2rem;
        display: flex;
        align-items: center;
        margin-bottom: 4rem;
        border-radius: 3rem;
    }

    &__icon {
        width: 1.5rem;
        height: 1.5rem;
        margin: 0.3rem;
    }

    &__date {
        font-family: $text-mulish;
        font-size: 1.4rem;
        font-weight: 600;
        // line-height: 2rem;
        text-align: center;
        color: $color-white;
    }

    &__details {
        box-shadow: 0px 1px 4.8px 0px #00000040;
        // width: 52%;
        // border: 1px solid red;
        border-radius: 1.6rem;
    }

    &__heading {
        font-family: $text-mulish;
        font-size: 2rem;
        font-weight: 700;
        line-height: 2rem;
        text-align: left;
        margin: 2rem;

    }

    &__row {
        display: flex;
        justify-content: space-between;
        margin: 2rem 2rem;

        &--total {
            padding-top: 2rem;
            border-top: 1px dashed $color-black;
        }
    }

    &__charges {
        &-details {
            display: flex;
            align-items: center;
        }

        &-discount {
            background-color: $color-primary;
            padding: .6rem;
            border-radius: .5rem;
            color: $color-white;
            margin-left: .5rem;
        }

        &-note {
            font-size: 1rem;
        }
    }

    &__label {

        font-family: $text-mulish;
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 2rem;


        &--total {
            font-size: 1.6rem;
            font-weight: 600;
        }

    }

    &__value {

        font-family: $text-mulish;
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 2rem;

        &--total {
            font-size: 1.8rem;
            font-weight: 600;
        }
    }

    &__button--box {
        // margin: 2rem 0rem 1.5rem 0rem;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 4rem;
    }

    &__button {
        width: 60%;
        height: 100%;
        border: none;
        background-color: $color-primary;
        color: white;
        font-size: 1.8rem;
        border-radius: 3rem;
        cursor: pointer;

        &:hover {
            background-color: rgba($color-primary, 0.9);
        }

        &:active {
            transform: translateY(1px);
        }
    }
}