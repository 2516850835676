.homeMainSection {
    display: flex;
    justify-content: center;
    background-color: $color-primary;
    padding: 0rem 2rem;
    padding-bottom: 5rem;

    &__container {
        width: 95%;
        display: flex;
        justify-content: space-between;
    }

    &__left {
        width: 40%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &__right {
        width: 60%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__heading {
        font-size: 3.3rem;
        font-weight: bold;
        margin-bottom: 1.5rem;
        color: $color-white;
        text-align: center;
        font-family: "Volkhov", serif;
    }

    &__para {
        font-size: 1.2rem;
        color: $color-white;
        text-align: center;
        line-height: 1.8rem;
        width: 70%;
        font-family: "Mulish", sans-serif;

        &:not(:last-child) {
            margin-bottom: 3rem;
        }
    }

    &__img {
        width: 100%;
    }
}

@media (max-width: 600px) {
    .homeMainSection {
        display: flex;
        justify-content: center;
        background-color: $color-primary;
        padding: 0rem 2rem;
        padding-bottom: 5rem;

        &__container {
            width: 95%;
            display: flex;
            flex-direction: column-reverse;
            justify-content: space-between;
            gap: 2rem;
            // border: 1px solid red;
        }

        &__left {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        &__right {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &__heading {
            font-size: 2.7rem;
            font-weight: bold;
            margin-bottom: 1.5rem;
            color: $color-white;
            text-align: center;
            font-family: "Volkhov", serif;
        }

        &__para {
            font-size: 1rem;
            color: $color-white;
            text-align: center;
            line-height: 1.8rem;
            width: 70%;
            font-family: "Mulish", sans-serif;

            &:not(:last-child) {
                margin-bottom: 3rem;
            }
        }

        &__img {
            width: 100%;
        }
    }
}