.documentUpload {
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    &__passportVerify {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba($color-black, .5);

    }

    &__form {
        margin: 5%;
        box-shadow: 0px 1px .45rem 0px rgba($color-black, .25);

        border-radius: .5rem;
        padding: 2.2rem;

        &-box {
            margin: 1rem;
            display: flex;

            @media (max-width: 600px) {
                flex-direction: column;
            }
        }

        min-width: 80%;
    }

    &__heading {
        font-family: $text-mulish;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.5rem;
        text-align: left;
        color: black;
        margin-bottom: 2rem;

        @media (max-width: 600px) {
            font-size: 1.8rem;
        }
    }

    &__input-wrapper {
        display: flex;
        flex-direction: column;
        width: 30%;
        margin: .5rem 1.5rem;
    }

    &__upload {
        &-section {
            display: flex;
            justify-content: space-between;
            margin-bottom: 1.5rem;
            align-items: center;
            padding-right: 1.2rem;

            &--text {
                display: flex;
                align-items: center;
            }
        }

        &-icon1 {
            width: 1.7rem !important;
            height: 1.7rem !important;
            margin-right: .5rem;
        }

        &-text {
            font-family: $text-mulish;
            font-size: 16px;
            font-weight: 700;
            color: $color-black;
        }

        &-icon2 {
            width: 2rem;
            height: 2rem;
        }
    }

    &__preview {
        height: 18rem;
        width: 18rem;
        object-fit: contain;
        border-radius: 1.4rem;
        box-shadow: 0px 1px .45rem 0px rgba($color-black, .25);

        @media (max-width: 600px) {
            height: 14rem;
            width: 14rem;
            border-radius: 1.2rem;
        }
    }

    &__error {
        color: red;
        font-size: 1.2rem;
        width: 20rem;
        overflow: hidden;
        margin-top: 1rem;
        padding-left: .5rem;
    }

    &__submit {
        height: 4rem;
        border-radius: 0.7rem;
        display: flex;
        justify-content: flex-end;
        width: 100%;

        &-button {
            font-family: $text-mulish;
            font-size: 1.6rem;
            font-weight: 400;
            // line-height: 2.4rem;
            text-align: center;
            width: 11rem;
            height: 100%;
            border-radius: 0.7rem;
            border: none;
            outline: none;
            background-color: #D9D9D9;

            @media (max-width: 600px) {
                font-size: 1.3rem;
                width: 9rem;
            }

            &:active {
                transform: translateY(1px);
            }

            &:not(:disabled) {
                background-color: $color-primary;
                color: $color-white;
                cursor: pointer;
            }
        }



    }

    &__button {
        display: flex;
        width: 80%;
        justify-content: space-between;
        margin: 4rem 0rem;
        height: 4rem;

        &-action {
            font-family: $text-mulish;
            font-size: 1.6rem;
            font-weight: 400;
            // line-height: 2.4rem;
            text-align: center;
            width: 11rem;
            height: 100%;
            border-radius: 0.7rem;
            border: none;
            outline: none;
            background-color: #D9D9D9;

            @media (max-width: 600px) {
                font-size: 1.3rem;
                width: 9rem;
            }

            &:not(:disabled) {
                background-color: $color-primary;
                color: $color-white;
                cursor: pointer;
            }

        }
    }


}