.pagination-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem 1rem;
  width: 90%;
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); // 4 cards per row
  gap: 20px;
  margin-bottom: 20px;
}

.card {
  padding: 20px;
  border: 1px solid #ddd;
  background-color: #f9f9f9;
  text-align: center;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.pagination__button {
  padding: 8px 12px;
  border: none;
  background-color: #f1f1f1;
  cursor: pointer;
}

.pagination__button--active {
  background-color: #007bff;
  color: white;
}

.pagination__button--prev,
.pagination__button--next {
  font-weight: bold;
}



@media screen and (max-width: 1000px) {
  .card-grid {
    grid-template-columns: repeat(3, 1fr); // 2 cards per row
  }
}

@media screen and (max-width: 800px) {
  .card-grid {
    grid-template-columns: repeat(2, 1fr); // 2 cards per row
  }
}

@media screen and (max-width: 450px) {
  .card-grid {
    grid-template-columns: repeat(1, 1fr); // 2 cards per row
  }

}