.navigation {
    width: 8%;
    // position: fixed;
    background-color: #F1F5F9;
    box-shadow: 0px 4px 4px 0px #00000040;

    @media (max-width: 600px) {
        width: 15%;
    }

    &__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 3rem;
    }

    &__box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;
    }

    &__topic {
        font-size: 1.6rem;
        font-weight: 800;
        line-height: 2rem;
        text-align: center;
        color: #6B7280;
        margin: .5rem;

        @media (max-width: 600px) {
            font-size: 1.2rem;
        }

        &--filled {
            color: $color-primary !important;
        }
    }

    &__icon {
        width: 3.2rem;
        height: 3.2rem;
        color: #6B7280;
        margin: .5rem;

        @media (max-width: 600px) {
            width: 2rem;
            height: 2rem;
        }

        &--filled {
            color: $color-primary !important;
        }
    }

    &__icon2 {
        width: 3.2rem;
        height: 7rem;
        color: #6B7280;

        @media (max-width: 600px) {
            // height: 12rem;
            width: 2rem;
        }

        &--filled {
            color: $color-primary !important;
        }
    }

}