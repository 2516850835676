*{
    padding: 0;
    margin: 0;
    box-sizing: inherit;
}

html{
    font-size: 62.5%;

    @include response(tab-land){
        font-size: 56.25%; // 9px
    }

    @include response(tab-port){
        font-size: 60%; // 8px
    }

    @include response(big-desktop-small){
        font-size: 70%; // 8px
    }

    @include response(big-desktop-small-2){
        font-size: 80%; // 8px
    }

    @include response(big-desktop){
        font-size: 85%; // 12px
    }

    @include response(big-desktop-2){
        font-size: 90%; // 12px
    }

    @include response(big-desktop-3){
        font-size: 120%; // 12px
    }

    @include response(big-desktop-4){
        font-size: 180%; // 12px
    }
}

body{
    font-family: "Montserrat",sans-serif;
    font-weight: 400;
    box-sizing: border-box;
}
