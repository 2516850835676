.visaReviewCard {
    width: 45%;
    border: 1px solid #EDEDED;
    box-shadow: 0px 1px 2px -1px #0000001A;
    box-shadow: 0px 1px 3px 0px #0000001A;
    border-radius: 1.6rem;
    padding: 2rem 2rem;
    margin: 1%;
    // border: 1px solid red;

    @media (max-width: 600px) {
        width: 100%;
        margin: 0%;
    }

    &__box {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #F3F4F6;
        padding: 1rem 0rem;
    }

    &__img-box {
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        margin-right: 2rem;
    }

    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
        border: 1px solid #9CA3AF;
        box-shadow: 0px 2px 4px 1px #0000000D inset;

    }

    &__name {
        font-family: "Volkhov", serif;
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 2.4rem;
    }

    &__icon {
        width: 1.2rem;
        height: 1.2rem;
        fill: #6B7280;
    }

    &__location {
        font-family: "Mulish", sans-serif;
        font-size: 1.3rem;
        font-weight: 400;
        line-height: 1.4rem;
        display: flex;
        align-items: center;

    }

    &__rating {
        margin-top: 1rem;
    }

    &__mainLine {
        font-family: "Volkhov", serif;
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 2.4rem;
        margin-top: 1rem;
        color: $color-black;
    }

    &__review {
        font-family: "Mulish", sans-serif;
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 2.4rem;
        color: $color-black;
        margin-top: 1rem;
    }
}