.navbar {
    background-color: $color-primary;
    height: 6.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: relative;
    color: $color-white;

    &__box {
        width: 90%;
        height: 5.2rem;
        margin: 0 auto;
        padding: 0 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }


    &__logo {
        width: 13rem;
        height: 5.2rem;
        margin-right: 1rem;
    }

    &__menu {
        list-style: none;
        display: flex;
        align-items: center;

        &-item {
            margin: .9rem 2rem;
            position: relative;
            padding-bottom: .2rem;

        }

        &-link {
            text-decoration: none;
            font-weight: 500;
            color: $color-white;
        }

        &-btn {
            width: 8.75rem;
            height: 3.12rem;
            border-radius: .5rem;
            border: none;
            background-color: $color-secondary;
            color: $color-white;
            font-size: 1.2rem;
            cursor: pointer;
            transition: all .5s;

            &:active {
                transform: translateY(2px);
            }
        }
    }

    &__currency-select {
        border: none;
        background-color: transparent;
        color: white;
        outline: none;
        font-size: 1.2rem;
        cursor: pointer;

        & option {
            background-color: $color-primary;
        }
    }

    &__menu-icon {
        display: none;
    }

    &__dashboard-icon {
        width: 2.5rem;
        height: 2.5rem;
    }

}

@media (max-width: 600px) {
    .navbar {
        &__nav-elements {
            position: absolute;
            right: 0;
            top: 6.5rem;
            background-color: $color-primary;
            width: 100%;
            height: 0px;
            transition: all 0.3s ease-out;
            overflow: hidden;
            z-index: 9999;

            &.active {
                height: calc(250px - 6.5rem);
            }
        }

        &__menu-icon {
            display: block;
            cursor: pointer;

        }

        &__menu {
            list-style: none;
            display: flex;
            flex-direction: column;

            &-item {
                margin-top: 15px;
            }

            &-link {
                text-decoration: none;
                font-weight: 500;
                color: $color-white;
            }

            &-btn {
                width: 8.75rem;
                height: 3.12rem;
                border-radius: .5rem;
                border: none;
                background-color: $color-secondary;
                color: $color-white;
                font-size: 1.2rem;
                cursor: pointer;
                transition: all .5s;
                margin-top: 1rem;

                &:active {
                    transform: translateY(2px);
                }
            }
        }
    }
}