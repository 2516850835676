.terms {
  font-family: Arial, sans-serif;
  color: #333;
  padding: 3rem 2rem;

  &__title {
    font-size: 3rem;
    margin-bottom: 2rem;
    color: $color-primary;
  }

  &__intro {
    font-size: 1.8rem;
    margin-bottom: 2rem;
    line-height: 1.6;
    color: #555;
  }

  &__section {
    margin-bottom: 3rem;
  }

  &__heading {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: $color-primary;
  }

  &__text {
    font-size: 1.5rem;
    line-height: 1.2;
    color: #555;
    margin-bottom: 1rem;
  }
}