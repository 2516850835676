.footer {
  background-color: $color-blue;
  color: $color-white;
  // width: 100%;

  &__container {
    display: flex;
    // border: 1px solid red;
    width: 100%;

    @media (max-width: 600px) {
      flex-direction: column;
    }
  }

  &__payment-container {
    display: flex;

    // border: 1px solid red;
    // justify-content: space-between;
    @media (max-width: 600px) {
      justify-content: space-between;
      flex-direction: row;
      width: 100%;
      margin-top: 2rem;
    }
  }

  &__section--middle {
    display: flex;
    flex-direction: row;

    @media (max-width: 600px) {
      flex-direction: column;
      gap: 2rem;
    }
  }

  &__top {
    display: flex;
    // justify-content: center;
    align-items: center;
    padding: 4rem 0rem;
  }

  &__section {
    display: flex;
    flex-direction: column;
    padding-left: 4%;
    padding-right: 4%;

    &--logo {
      width: 30%;

      // border: 1px solid red;
      @media (max-width: 600px) {
        flex-direction: row;
        justify-content: space-between;

        width: 100%;
      }
    }

    &--middle {
      width: 50%;

      // border: 1px solid red;
      @media (max-width: 600px) {
        width: 50%;
      }
    }

    &--payment {
      width: 50%;
      // border: 1px solid red;

      @media (max-width: 600px) {
        width: 50%;
      }
    }

    &--links {
      width: 50%;

      @media (max-width: 600px) {
        width: 100%;
      }
    }

    &--contact {
      width: 50%;

      @media (max-width: 600px) {
        width: 100%;
      }
    }


  }

  &__logo-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // justify-content: space-between;
    // border: 1px solid red;

  }

  &__logo {
    width: 20rem;
    height: 7rem;
    // border: 1px solid red;
  }

  &__currency {
    width: 70%;
    margin-bottom: 2rem;

    &-select {
      background-color: $color-blue;
      width: 100%;
      border: 1px solie rgba($color-white, 0.2);
      padding: 1rem;
      border-radius: 0.5rem;
      color: rgba($color-white, 0.2);
      outline: none;
    }
  }

  &__heading {
    font-family: "Mulish", sans-serif;
    font-size: 1.7rem;
    font-weight: 700;
    line-height: 1.88rem;
    color: rgba($color-white, 0.8);
    margin-bottom: 1rem;
  }

  &__links-list {
    list-style: none;
    text-align: left;
  }

  &__link {
    font-family: "Mulish", sans-serif;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 2.5rem;
    color: rgba($color-white, 0.6);
    text-decoration: none;

    &:hover {
      color: $color-secondary;
    }
  }


  &__address {


    &-label {
      font-family: "Mulish", sans-serif;
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 3rem;
      color: rgba($color-white, 0.8);
    }

    &-text {
      font-family: "Mulish", sans-serif;
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 2rem;
      color: rgba($color-white, 0.6);
    }

  }

  &__phone {
    &-label {
      font-family: "Mulish", sans-serif;
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 2.5rem;
      margin-top: 1rem;
      color: rgba($color-white, 0.8);
    }

    &-number {
      font-family: "Mulish", sans-serif;
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 2.5rem;
      color: rgba($color-white, 0.6);
    }
  }

  &__payment-methods {
    display: flex;
    flex-wrap: wrap;
    // align-items: center;
    flex-direction: column;
  }

  &__payment-method {
    height: 3.5rem;
    width: 6rem;
    margin: .5rem .5rem .5rem 0rem;
    border-radius: .1rem;
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #00000033;
    height: 5rem;

    padding: 0 8%; // Added padding for alignment

    @media (max-width: 600px) {
      flex-direction: column-reverse;
      justify-content: center;
      gap: 1.3rem;
      height: 8rem;
    }
  }

  &__copyright {
    font-family: "Mulish", sans-serif;
    font-size: 1.4rem;
    font-weight: 400;
    // line-height: 3rem;
    color: rgba($color-white, 0.6);
    // margin-top: 1rem;

    @media (max-width: 600px) {
      text-align: center;
    }
  }

  &__social-icon {
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
    fill: white;
  }

  &__social {
    display: flex;
    gap: 2rem; // Space between icons

    &-link {}

    &-icons {
      width: 2rem;
      height: 2rem;
      fill: white;
    }
  }

  &__company-box {
    padding-left: 5rem;
    margin-top: 1rem;

    @media (max-width: 600px) {
      padding-left: 0;
    }
  }

  &__company {
    font-family: $text-mulish;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.8rem;
    text-align: left;
    margin-top: 1rem;


    &-name {
      font-family: $text-mulish;
      font-size: 1.1rem;
      font-weight: 100;
      line-height: 2.5rem;
      text-align: left;
      color: rgba($color-white, 0.8);
    }
  }

  &__email-box {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    //  margin-left: -.6rem;
  }

  &__email {
    display: flex;
    font-size: 1.3rem;
    font-weight: 400;
    color: rgba($color-white, 0.8);
    align-items: center;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: $color-secondary;
    }
  }
}