.link-expired {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #fdf5f5;
  color: #343a40;

  &__heading {
    font-size: 4rem;
    font-weight: bold;
    color: #ff6b6b;
    margin: 0;
  }

  &__text {
    font-size: 1.5rem;
    margin: 1rem 0;
  }

  &__link {
    padding: 0.75rem 1.5rem;
    background-color: #343a40;
    color: #ffffff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #ff6b6b;
    }
  }
}