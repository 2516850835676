.visa-offer {
    width: 100%;
    padding: 1rem;
    background-color: $color-primary;
    color: $color-white;
    display: flex;
    border-radius: 1.6rem;
    margin-top: 2rem;
    align-items: center;
    margin-left: .5rem;

    &__image {
        height: 6.6rem;
        width: 6.6rem;
        margin-right: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;

        &-img {
            width: 100%;
        }
    }

    &__title {
        font-size: 1.5rem;
        font-weight: 700;
        // line-height: 2rem;
        text-align: left;
        margin-bottom: .5rem;
    }

    &__description {
        font-family: $text-mulish;
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 1.7rem;
        text-align: left;
    }


}