.visaReview {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem 2rem;
    width: 100%;
    // margin-top: 3rem;

    &__heading {
        text-align: center;
        font-size: 4rem;
        font-weight: 600;
        line-height: 4.8rem;
        font-family: "Mulish", sans-serif;
        margin-bottom: 1.5rem;
        letter-spacing: .2rem;
        color: $color-black;
    }

    &__detail {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 2rem;
    }

    &__star {
        margin-right: 1rem;
    }

    &__para {
        font-family: "Mulish", sans-serif;
        font-size: 1.8rem;
        font-weight: 400;
        line-height: 2.8rem;
        color: $color-black;
    }

    &__card-box {
        width: 90%;
        display: flex;
        flex-wrap: wrap;
        // border: 1px solid red;
        justify-content: space-between;

        @media (max-width: 600px) {
            flex-direction: column;
            align-items: center;
            width: 100%;
            gap: 2rem;
            // border: 1px solid red;
        }
    }
}