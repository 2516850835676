.not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background-color: #f8f9fa;
  color: #343a40;

  &__heading {
    font-size: 6rem;
    font-weight: bold;
    color: #ff6b6b;
    margin: 0;
  }

  &__text {
    font-size: 1.5rem;
    margin: 1rem 0;
  }

  &__link {
    padding: 0.75rem 1.5rem;
    background-color: #343a40;
    color: #ffffff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;

    &:hover {
      background-color: #ff6b6b;
    }
  }
}