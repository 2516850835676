.visaPriceCalculator {
    border: 1px solid #E5E7EB;
    box-shadow: 0px 25px 50px -12px #00000040;
    border-radius: 1.6rem;
    padding: 1.5rem 2rem;
    margin-top: 2rem;
    width: 100%;

    &__box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 2px solid #B8B8B8BF;
        padding: .8rem .3rem;
        margin-bottom: 1.5rem;
    }

    &__box--1 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: .5rem 0rem;
        margin-bottom: 0.5rem;
        position: relative;
    }

    &__label {
        display: flex;
        align-items: center;
        font-family: "Mulish", sans-serif;
        font-size: 1.6rem;
        font-weight: 600;
        // line-height: 2.4rem;
    }

    &__label--1 {
        display: flex;
        align-items: center;
        font-family: "Mulish", sans-serif;
        font-size: 1.4rem;
        font-weight: 600;
        line-height: 1.8rem;
    }

    &__icon {
        width: 2rem;
        height: 2rem;
        margin-right: 1rem;
    }

    &__tax {
        font-size: 1.2rem;
        color: #666;
        display: block;
        font-weight: 100;
    }

    &__controls {
        display: flex;
        align-items: center;
    }

    &__num {
        width: 2.5rem;
        height: 2.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #E5E7EB;
        border-radius: 50%;
        font-size: 1.8rem;
        cursor: pointer;

        &-text {
            font-family: "Mulish", sans-serif;
            font-size: 1.6rem;
            font-weight: 400;
            line-height: 2.4rem;
            text-align: center;
            margin: 0rem 1rem;
        }
    }

    &__discount {
        color: $color-white;
        font-size: 1.1rem;
        background-color: rgba($color-primary, 0.6);
        display: inline-block;
        padding: 0rem .5rem;
        border-radius: 6%;
    }

    &__input-date {
        width: 99%;
        height: 3rem;
        padding: .5rem 1rem;
        outline: none;
        border: 1px solid $color-black;
        font-size: 1.4rem;
        border-radius: 0.7rem;
    }

    &__input-placeholder {
        position: absolute;
        content: "Departure Date";
        font-size: 1.5rem;
        top: 28%;
        left: 3%;
        background-color: $color-white;
    }

    &__display-none {
        display: none;
    }

    &__line {
        border-bottom: 1px dashed $color-grey;
        margin: 0.5rem 0rem;
    }

    &__button {
        width: 100%;
        height: 4rem;
        border: none;
        background-color: $color-secondary;
        color: $color-white;
        font-size: 1.5rem;
        border-radius: 0.7rem;
        margin: .5rem 0rem;
        // box-shadow: 0rem .1rem 1rem rgba($color-black, 0.5);
        transition: all .2s;
        cursor: pointer;

        &:active {
            transform: translateY(1px);
        }
    }



}