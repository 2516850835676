.login {
    width: 100%;
    height: 80vh;
    display: flex;
    // margin: 1rem;
    justify-content: center;
    align-items: center;

    &__wrapper {
        border: 1px solid #E5E7EB;
        border-radius: 1.6rem;
        padding: 2rem 3rem;
    }

    &__header {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0rem 6rem 2rem 0rem;
    }

    &__logo {
        width: 4rem;
        height: 4rem;
        margin-right: 1.5rem;
    }

    &__icon {
        width: 100%;
        height: 100%;

    }

    &__title {}

    &__main-title {
        font-family: $text-mulish;
        font-size: 1.6rem;
        font-weight: 100;
        // line-height: 2.8rem;
        color: $color-primary;
    }

    &__sub-title {
        font-family: $text-mulish;
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 2rem;
        color: $color-primary;
    }

    &__form {}

    &__label {
        font-family: $text-mulish;
        font-size: 1.4rem;
        font-weight: 600;
        // line-height: 2.4rem;
        margin-bottom: 2rem;
    }


    .react-tel-input {
        margin-top: .5rem;

        input {
            width: 100%;
            height: 4rem;
            font-size: 1.3rem;
        }
    }


    &__error {
        font-size: 1.6rem;
        color: red;
    }

    &__button {
        margin-top: 2rem;
        width: 100%;
        height: 4rem;
        color: $color-white;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color-primary;
        border: none;
        outline: none;
        border-radius: 1rem;
        font-size: 1.4rem;
        cursor: pointer;

        &:active {
            transform: translateY(1px);
        }

        &:hover {
            background-color: rgba($color-primary, 0.9);
        }
    }

}