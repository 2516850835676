.reviewCard {
    display: inline-block;
    border: 1px solid #EDEDED;
    font-size: 1.4rem;
    line-height: 2rem;
    border-radius: 1rem;
    box-shadow: 0px 1px 2px -1px #0000001A;
    box-shadow: 0px 1px 3px 0px #0000001A;


    &:not(:last-child) {
        margin-right: 2rem;
    }

    &__img-box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 12rem;
        margin-bottom: 1rem;
    }

    &__img {
        width: 8rem;
        height: 8rem;
        border-radius: 50%;
        object-fit: cover;
    }

    &__detail {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;
    }

    &__review {
        font-weight: 400;
        color: $color-black;
        width: 26rem;
        height: 9.8rem;
        overflow: hidden;
        padding: 0rem 2rem;
        text-align: center;
        margin-bottom: 1rem;
    }

    &__name {
        font-weight: 700;
        color: $color-black;
        margin-bottom: .5rem;
    }

    &__type {
        font-weight: 400;
        color: rgba($color-black, 0.5);
    }
}